import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  tableLoading,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";

const Expanses = () => {
  const { t } = useTranslation();
  const history = useHistory();

  let { loading, setLoading, dataPaginating } = useContext(SettingsContext);

  //new type
  let [newType, setNewType] = useState();

  //new type description
  let [description, setDescription] = useState();

  //useEffect == componentDidMount()
  useEffect(() => {}, []);

  //post req of food item add
  const newTypeAxios = async () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("name", newType);
    formData.append("description", description);
    const url = BASE_URL + "/settings/new-expanse-type";
    return axios
    .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
    })
    .then((res) => {
        if(res.data.length > 0){
            setLoading(false);
            toast.success(`${_t(t("Expense type has been added"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
            });
        }
    })
    .catch((error) => {
        setLoading(false);
    });
  }

  //send to server
  const handleSubmit = async (e) => {
    e.preventDefault();
    //check if expense type is set
    if(!newType){
        toast.error(`${_t(t("Please enter valid type name"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
        });
    }else if(!description){
        toast.error(`${_t(t("Please enter some description"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
        });
    }else{
        newTypeAxios();
    }
  };

  const handleChange = (e) =>{
    setNewType(e.target.value);
  }

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  }

  return (
    <>
      <Helmet>
        <title>{_t(t("Add New Item"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Add Expense Types"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center"></div>
                          </div>
                        </div>

                        {/* Form starts here */}
                        <form
                          className="row card p-2 mx-3 mb-5 sm-text"
                          onSubmit={handleSubmit}
                        >
                          <div className="col-12">

                            <div className="form-group mt-3">
                              <div className="mb-2">
                                <label htmlFor="name" className="control-label">
                                  {_t(t("Name"))}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="mb-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  name="name"
                                  onChange={handleChange}
                                  value={newType}
                                  placeholder="e.g. utility bill"
                                  required
                                />
                              </div>
                            </div>

                            <div className="form-group mt-3">
                              <div className="mb-2">
                                <label htmlFor="name" className="control-label">
                                  {_t(t("Description"))}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="mb-2">
                                <textarea
                                  type="text"
                                  className="form-control"
                                  id="description"
                                  name="description"
                                  onChange={handleDescriptionChange}
                                  value={description}
                                  placeholder="e.g. miscellaneous payment"
                                  required
                                >
                                </textarea>
                              </div>
                            </div>

                            <div className="form-group mt-5 pb-2">
                              <div className="col-lg-12">
                                <button
                                  className="btn btn-primary px-5"
                                  type="submit"
                                >
                                  {_t(t("Save"))}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default Expanses;
