import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  modalLoading,
  tableLoading,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chart from "react-apexcharts";
import Moment from "react-moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";

const CustomWise = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let { loading, setLoading, dataPaginating } = useContext(SettingsContext);

  // States hook here
  const [amountChart, setAmountChart] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [],
      },
    },
    series: [
      {
        name: _t(t("Amount")),
        data: [],
      },
    ],
  });

  //all data
  const [reportData, setReportData] = useState(null);

  const [overallData, setOverallData] = useState({
    total_expanses: null,
    total_sales: null,
    total_profit: null
  });

  // paidMoney
  const [paidMoney, setPaidMoney] = useState(0);
  //return
  const [returnMoneyUsd, setReturnMoneyUsd] = useState(0);

  //settle order
  const [checkOrderDetails, setCheckOrderDetails] = useState({
    item: null,
    settle: false,
    uploading: false,
    payment_type: null,
    payment_amount: null,
  });

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  //useEffect == componentDidMount()
  useEffect(() => {
    setLoading(true);
    getCustomWise();
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  //get Monthly reports
  const getCustomWise = () => {
    let formData = {
        start_date: startDate,
        end_date: endDate
    };
    const url = BASE_URL + "/settings/get-profit-lose-report";
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setReportData(res.data[0]);
        setOverallData({
          total_expanses: res.data[1][0][0],
          total_sales: res.data[1][0][1],
          total_profit: res.data[1][0][2]
        });
      })
      .catch((error) => {});
  };

  //get Monthly reports filter
  const getCustomWiseSelected = (startDate, endDate) => {

    if (startDate !== null && endDate !== null) {
      setLoading(true);
      var fromDate = startDate.toISOString();
      const url = BASE_URL + "/settings/get-profit-lose-report";
      let formData = {
        start_date: startDate,
        end_date: endDate
      };
      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
        //   let formattedAmount = res.data[1].map((item) =>
        //     parseFloat(formatPrice(item))
        //   );
          setReportData(res.data[0]);
          setOverallData({
            total_expanses: res.data[1][0][0],
            total_sales: res.data[1][0][1],
            total_profit: res.data[1][0][2]
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Profit Lose report"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Profit Lose report"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row gx-2 justify-content-between">
                          <div className="col-12 col-md-4 t-mb-15 mb-md-0">
                            <div className="d-flex justify-content-between">
                              <div className="sm-text text-white text-right rounded-sm col-8">
                                <DatePicker
                                  onChange={(dates) => {
                                    const [start, end] = dates;
                                    setStartDate(start);
                                    setEndDate(end);
                                  }}
                                  className="sm-text form-control py-1"
                                  style={{ zIndex: "10" }}
                                  placeholderText={_t(t("Pick a custom range")) + ".."}
                                  startDate={startDate}
                                  endDate={endDate}
                                  selected={startDate}
                                  selectsRange
                                />
                              </div>
                              <div className="sm-text text-white text-md-right rounded-sm col-12 col-md-3 ml-2 ml-md-0">
                                <button
                                  className="btn btn-primary btn-sm px-3"
                                  onClick={() => getCustomWiseSelected(startDate, endDate)}
                                >
                                  {_t(t("Filter"))}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {reportData && reportData.length > 0? 
                          <div className="row gx-2 justify-content-between">
                            <div className="col-12 col-md-12 t-mb-15 mb-md-0">
                              <div className="d-flex justify-content-between">
                                <div className="sm-text text-white text-left rounded-sm col-4">
                                  <h5 className="mr-3 mt-6 mb-0">Total Sales: <span style={{color: '#999'}}>{formatPrice(overallData.total_sales)}</span></h5>
                                </div>
                                <div className="sm-text text-white text-left rounded-sm col-4">
                                  <h5 className="mr-3 mt-6 mb-0">Total Expenses: <span style={{color: '#999'}}>{formatPrice(overallData.total_expanses)}</span></h5>
                                </div>
                                <div className="sm-text text-white text-left rounded-sm col-4">
                                  <h5 className="mr-3 mt-6 mb-0">Total Profit: <span style={{color: '#999'}}>{formatPrice(overallData.total_profit)}</span></h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        :
                          ''
                        }
                        

                        {reportData !== null &&
                          reportData !== undefined &&
                          reportData.length > 0 && (
                            <div className="row gx-2 justify-content-center t-pb-15 t-pt-15">
                              <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                                <table className="table table-bordered table-hover min-table-height">
                                  <thead className="align-middle">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("S/L"))}
                                      </th>

                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("Date"))}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("Expenses"))}
                                      </th>
                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("Sales"))}
                                      </th>

                                      <th
                                        scope="col"
                                        className="sm-text text-capitalize align-middle text-center border-1 border"
                                      >
                                        {_t(t("Profit"))}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="align-middle">
                                    {/* loop here*/}
                                    {reportData.map((item, index) => {
                                      return (
                                        <tr
                                          className="align-middle"
                                          key={index}
                                        >
                                          <th
                                            scope="row"
                                            className="xsm-text text-capitalize align-middle text-center"
                                          >
                                            {index + 1}
                                          </th>

                                          <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                            {item[0]}
                                          </td>

                                          <td className="xsm-text text-capitalize align-middle text-center">
                                            {formatPrice(item[1])}
                                          </td>

                                          <td className="xsm-text align-middle text-center">
                                            {formatPrice(item[2])}
                                          </td>

                                          <td className="xsm-text align-middle text-center">
                                            {formatPrice(item[3])}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default CustomWise;