import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../../BaseUrl";

//Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Moment
import Moment from "react-moment";
import moment from 'moment';

//functions
import {
  _t,
  getCookie,
  tableLoading,
} from "../../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";

//pages & includes
import ManageSidebar from "../ManageSidebar";

//context consumer
import { SettingsContext } from "../../../../../contexts/Settings";
// import { FoodContext } from "../../../../../contexts/Food";

const Expanses = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //getting context values here
  let { loading, setLoading, dataPaginating } = useContext(SettingsContext);

  // let {
  //   setFoodForSearch,
  //   foodGroupForSearch,
  //   propertyGroupForSearch,
  //   variationForSearch,
  // } = useContext(FoodContext);

  //new type
  let [expanseTypes, setExpanseTypes] = useState([]);
  let [staffMembers, setStaffMembers] = useState([]);

  let [expanseId, setExpanseId] = useState(null);
  let [expanseAmount, setExpanseAmount] = useState(null);
  let [staffMemberId, setStaffMemberId] = useState(null);
  let [startDate, setStartDate] = useState(new Date);
  

  //useEffect == componentDidMount()
  useEffect(() => {
    //setLoading(true);
    async function fetchExpanseTypesAndStaffMembers() {
      let formData = new FormData();
      const url = BASE_URL + "/settings/get-expanse-types-and-staff-members";
      return axios
      .post(url,formData,{
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        console.log(res.data[0]);
        setExpanseTypes(res.data[0]);
        setStaffMembers(res.data[1]);
        //setLoading(false);
      })
      .catch((error) => {
          //setLoading(false);
      });
    }
    fetchExpanseTypesAndStaffMembers();
  }, []);

  //post req of food item add
  const addExpanseAxios = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("expanse_type_id", expanseId);
    formData.append("expanse_amount", expanseAmount);
    formData.append("date", moment(startDate).format('YYYY/MM/DD'));
    formData.append("staff_member_id", staffMemberId);

    const url = BASE_URL + "/settings/add-new-expanse";
    return axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if(res.data){
          show_success_toast('Expanse added');
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        show_error_toast('Something went wrong');
      });
  };

  const show_success_toast = (message) =>{
    toast.success(`${_t(t(message))}`, {
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    });
  }

  const show_error_toast = (message) =>{
    toast.error(`${_t(t(message))}`, {
      position: "bottom-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    });
  }

  //send to server
  const handleSubmit = (e) => {
    e.preventDefault();

    if(!expanseId){
      show_error_toast("Please select a expense type");
      return;
    }

    if(!/^\d+(\.\d+)?$/.test(expanseAmount)){
      show_error_toast("Please enter a valid expense amount");
      return;
    }

    if(!startDate){
      show_error_toast("Please select a date");
      return;
    }

    if(!staffMemberId){
      show_error_toast("Please select a date");
      return;
    }
    
    addExpanseAxios();
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Add expense"))}</title>
      </Helmet>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
              <ManageSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-9 col-xxl-10 t-mb-30 mb-lg-0">
              <div className="t-bg-white">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${dataPaginating && "loading"}`}></div>
                    {/* spin loading ends */}

                    {/* Loading effect */}
                    {loading === true ? (
                      tableLoading()
                    ) : (
                      <div key="smtp-form">
                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15">
                          <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize">
                                  {_t(t("Add expense"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6 col-lg-7">
                            <div className="row gx-3 align-items-center"></div>
                          </div>
                        </div>

                        {/* Form starts here */}
                        <form
                          className="row card p-2 mx-3 mb-5 sm-text"
                          onSubmit={handleSubmit}
                        >
                          <div className="col-12">
                            <div className="form-group mt-2">
                              <div className="mb-2">
                                <label
                                  htmlFor="itemGroup"
                                  className="control-label"
                                >
                                  {_t(t("type"))}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <Select
                                options={expanseTypes}
                                components={makeAnimated()}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                classNamePrefix="select"
                                onChange={(object) =>{
                                  setExpanseId(object.id);
                                }}
                                maxMenuHeight="200px"
                                placeholder={
                                  _t(t("Please select a expense type")) + ".."
                                }
                              />
                            </div>
                            <div className="form-group mt-3">
                              <div className="mb-2">
                                <label htmlFor="name" className="control-label">
                                  {_t(t("Amount"))}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="mb-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="amount"
                                  name="amount"
                                  onChange={(e) =>{
                                    setExpanseAmount(e.target.value);
                                  }}
                                  // value={}
                                  placeholder="Expense amount"
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-group mt-3">
                              <div className="mb-2">
                                <label htmlFor="name" className="control-label">
                                  {_t(t("Date"))}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="mb-2">
                                <DatePicker 
                                  selected={startDate}
                                  onChange={date => setStartDate(date)}
                                  className="sm-text form-control py-1"
                                  style={{ zIndex: "10" }}
                                  dateFormat='yyyy-MM-dd'
                                />
                              </div>
                            </div>
                            <div className="form-group mt-3">
                              <div className="mb-2">
                                <label htmlFor="name" className="control-label">
                                  {_t(t("Staff Member"))}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="mb-2">
                                <Select
                                  options={staffMembers}
                                  components={makeAnimated()}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  classNamePrefix="select"
                                  onChange={(object) =>{
                                    setStaffMemberId(object.id);
                                  }}
                                  maxMenuHeight="200px"
                                  placeholder={
                                    _t(t("Please select a staff member")) + ".."
                                  }
                                />
                              </div>
                            </div>

                            <div className="form-group mt-5 pb-2">
                              <div className="col-lg-12">
                                <button
                                  className="btn btn-primary px-5"
                                  type="submit"
                                >
                                  {_t(t("Save"))}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default Expanses;
